// 用户列表请求
import request from '@/utils/request'

//  管理员重置密码
export function resetPassword(reqParams) {
  return request({
    url: `/user/update/reset-password`,
    method: 'put',
    data: reqParams
  })
}

export function getUser(param) {
  return request({
    url: `/user/get/page`,
    method: 'get',
    params: param
  })
}

export function addUserInfo(param) {
  return request({
    url: `/user/add`,
    method: 'post',
    data: param
  })
}

export function deletUserID(userId) {
  return request({
    url: `/user/delete/${userId}`,
    method: 'delete'
  })
}

export function searchUser(param) {
  return request({
    url: `/user/get/page`,
    method: 'get',
    params: param
    // data: {
    //   userId
    // }
  })
}

export function findUserList(userId, param) {
  return request({
    url: `/user/get/${userId}`,
    method: 'get'
    // params: param
  })
}

export function getCompanyInfo(param) {
  return request({
    url: `/user/get/company-info`,
    method: 'get',
    params: param
  })
}

export function updateUserState(data) {
  return request({
    url: `/user/upate/user-state`,
    method: 'put',
    data: data
  })
}

export function updateUser(reqParams) {
  return request({
    url: `/user/update`,
    method: 'put',
    data: reqParams
  })
}
// 根据用户ID查询用户权限信息
export function userRightOld(userId, usId) {
  return request({
    url: `/user/get/user-right/${userId}`,
    method: 'get',
    params: { usId: usId }
  })
}
export function userRight(userId, usId) { // 用户接口改成树形，2020-3-9 zeng
  return request({
    url: `/user/get/user-right-tree/${userId}`,
    method: 'get',
    params: { usId: usId }
  })
}
// 用户系统权限（按照菜单结构返回）
export function userRightNew(params) {
  return request({
    url: `/user/get/user-right-tree-new`,
    method: 'get',
    params: params
  })
}

// 赋予权限
export function updateUserRight(reqParams) {
  return request({
    url: `/user/update/user-right`,
    method: 'put',
    data: reqParams
  })
}
// 获取用户公司列表2019/7/30
export function userCompany(reqParams) {
  return request({
    url: `/company/get/userCompany`,
    method: 'get',
    params: reqParams
  })
}
// 获取用户公司id获取部门列表2019/7/30
export function getuserDepartment(ucId, udSupervisorId) {
  return request({
    url: `/department/get/userCompany`,
    method: 'get',
    params: {
      ucId: ucId,
      udSupervisorId: udSupervisorId
    }
  })
}
// 获取用户公司id获取部门列表2019/7/30
export function getuserPosition(udId, parentId) {
  return request({
    url: `/position/get/userPosition`,
    method: 'get',
    params: {
      udId: udId, // 部门id
      parentId: parentId// 父级职位id
    }
  })
}
// 获取用户系统2019/7/30
export function userSystem(userId) {
  return request({
    url: `/userSystem/get/userSystem`,
    method: 'get',
    params: {
      userId: userId
    }
  })
}
// 获取用户系统2020/3/3
export function allSystem() {
  return request({
    url: `/userSystem/get/page`,
    method: 'get',
    params: {
      pageNum: 1,
      pageSize: 1000
    }
  })
}
export function userRoleRightMap(id) {
  return request({
    url: `/userRoleRightMap/get/role`,
    method: 'get',
    params: {
      userId: id
    }
  })
}

// 用户管理-操作日志
export function getUserActionLog(params) {
  return request({
    url: 'user/getUserActionLog',
    method: 'post',
    data: params
  })
}

// 获取所有账号
export function getAllAccount(param) {
  return request({
    url: `/user/get/pageAll`,
    method: 'get',
    params: param
  })
}

// 当前账户有用的系统（被授权的）
export function getAuthorizationSystem(id) {
  return request({
    url: `/user/getAuthorizationSystem`,
    method: 'get',
    params: {
      userId: id
    }
  })
}

// 为账号授予系统(账号管理页)
export function userAuthorization(data) {
  return request({
    url: `/user/userAuthorization`,
    method: 'post',
    data: data
  })
}

// 为用户指派系统（权限分配页）
export function updateUserSystem(data) {
  return request({
    url: `/user/updateUserSystem`,
    method: 'put',
    data: data
  })
}
