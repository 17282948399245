<template>
  <div class="app-container">
    <el-form :model="searchForm" size="small" ref="searchForm" inline>
      <el-form-item label="公司名称">
        <!-- <el-cascader :options="companyList" v-model="searchForm.ucId" :show-all-levels="false"
            change-on-select @change="companyChange" :props="companyProps" clearable style="width:300px"></el-cascader> -->
        <el-select v-model="searchForm.ucId" :disabled="true" style="width:300px">
          <el-option v-for="company in companyList" :label="company.ucName" :value="company.ucId" :key="company.ucId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位名称">
        <el-input v-model="searchForm.upName" clearable placeholder="职位名"></el-input>
      </el-form-item>
      <el-form-item label="职位ID">
        <el-input v-model.number="searchForm.upId" clearable placeholder="职位ID"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="onClear()">清空</el-button>
        <el-button v-if="isShowButton" type="warning" size="small" @click="powerClick">授权</el-button>
        <el-button type="success" size="small" @click="batchValidation" :disabled="upIdSeletionArray.length < 1">批量生效</el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      v-loading="isLoading"
      :data="dataList"
      border
      height="calc(100% - 120px)"
      style="width: 100%;"
      row-key="upId"
      @selection-change="handleSelectionChange"
      size="small"
      :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
      <el-table-column type="selection" width="50" :reserve-selection="true" align="center"/>
      <el-table-column prop="upId" label="职位id" align="center" width="150px"></el-table-column>
      <el-table-column prop="upName" label="职位名称" align="center"></el-table-column>
      <el-table-column label="部门名称" prop="udName" align="center"></el-table-column>
      <el-table-column label="部门长称" prop="longName" align="center"></el-table-column>
      <el-table-column label="职位等级" prop="levelName" align="center"></el-table-column>
      <el-table-column label="更新时间" prop="configSystemTime" align="center">
        <template slot-scope="scope">
          <!-- <span>{{ new Date(scope.row.configSystemTime).format("yyyy-MM-dd") }}</span> -->
          <span>{{ scope.row.configSystemTime ? scope.row.configSystemTime.split('T')[0] : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配置系统" prop="configSystemTime" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.inductionSystemList" :key="index">{{ item.systemName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="configSystemStatus" width="150px" align="center">
        <template slot-scope="scope">
          <el-switch
            :disabled="!isShowButton"
            v-model="scope.row.configSystemStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="statusChange(scope.row)"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100px">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="入职岗位配置" placement="top">
            <svg-icon :disabled="!isShowButton" icon-class="config" @click="postConfigClick(scope.row)" class="table_icons" style="width: 16px; height: 16px; cursor:pointer;"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align: center;margin-top:2em">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- 授权 弹窗 -->
    <power-dialog
      :powerVisible="powerVisible"
      @closeDialog="closeDialog('power')"/>

    <!-- 入职岗位配置 弹窗 -->
    <config-dialog
      :configVisible="configVisible"
      :checkedSystemArray="checkedSystemArray"
      :configFormData="configFormData"
      @closeDialog="closeDialog('config')"
      @commitSuccess="commitSuccess()"/>
  </div>
</template>

<script>
import {
  getIsShowButtom,
  configureJobsPage,
  updateConfigureJobs,
  updateStatus
} from '@/api/post'
import { userCompany } from '@/api/account'
import postConfig from '../postConfHr/components/postConfigHr'
import powerConfig from './components/powerConfig'

export default {
  components: {
    'config-dialog': postConfig,
    'power-dialog': powerConfig
  },
  data() {
    return {
      isShowButton: false,
      isLoading: false,
      searchForm: {
        upId: '',
        upName: '',
        ucId: process.env.VUE_APP_COMPANY_ID
      },
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      companyList: [{ ucId: process.env.VUE_APP_COMPANY_ID, ucName: process.env.VUE_APP_COMPANY_NAME, ucParentId: 1023, hasMore: false }],
      companyProps: {
        label: 'ucName',
        value: 'ucId',
        children: 'childrenCompanyList'
      },
      upIdSeletionArray: [],
      configVisible: false,
      configFormData: {
        upId: 0,
        mainPeoples: ''
      },
      checkedSystemArray: [],
      powerVisible: false
    }
  },
  mounted() {
    this.getIsShowButton()
    // this.getAllCompany()
    this.getListConfigJob()
  },
  methods: {
    // 获取是否有操作按钮权限
    getIsShowButton() {
      getIsShowButtom().then(res => {
        if (res.code === '000000' && res.data) {
          this.isShowButton = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // 获取公司下拉列表
    getAllCompany() {
      userCompany()
        .then(response => {
          this.upIdSeletionArray = []
          if (response.code === '000000' && response.data) {
            this.companyList = JSON.parse(JSON.stringify(response.data))
            this.companyList.forEach(item => {
              if (item.hasMore) {
                item.childrenCompanyList = []
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.pageNum = 1
      this.getListConfigJob()
    },
    // 获取表格数据
    getListConfigJob() {
      this.$refs.multipleTable.clearSelection()
      this.upIdSeletionArray = []
      this.isLoading = true
      const params = {
        upId: this.searchForm.upId ? this.searchForm.upId : null,
        upName: this.searchForm.upName ? this.searchForm.upName : null,
        // ucId: this.searchForm.ucId ? this.searchForm.ucId[this.searchForm.ucId.length - 1] : null,
        ucId: this.searchForm.ucId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      configureJobsPage(params).then(response => {
        if (response.code === '000000' && response.data.list) {
          this.isLoading = false
          this.dataList = response.data.list
          this.total = response.data.total
        } else {
          this.dataList = []
        }
      }).catch(err => {
        this.dataList = []
        this.total = 0
        this.isLoading = false
        console.log(err)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListConfigJob()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListConfigJob()
    },
    // 选择上级公司后获取下级公司列表
    companyChange(val) {
      const id = val[val.length - 1]
      userCompany({ ucParentId: id }).then(response => {
        if (response.code === '000000' && response.data.length > 0) {
          response.data.forEach(item => {
            if (item.hasMore) {
              item.childrenCompanyList = []
            }
          })
          const children = JSON.parse(JSON.stringify(this.companyList))
          this.reFind(id, children, response.data)
          setTimeout(() => {
            this.companyList = children
          }, 100)
        }
      }).catch(err => {
        console.log(err)
      })
      this.$forceUpdate()
    },
    // 递归查询,公司id 级联数据，添加数据（使用参数做key如childrenCompanyList传入，第三层出现问题不显示，因此要改变的字段指定）
    reFind(id, arr, json) {
      arr.forEach((element) => {
        if (id === element.ucId) {
          if (json.length > 0) {
            element.childrenCompanyList = json
          }
        } else {
          if (element.childrenCompanyList) {
            this.reFind(id, element.childrenCompanyList, json)
          }
        }
      })
    },
    postConfigClick(row) {
      this.checkedSystemArray = []
      if (row.inductionSystemList) {
        row.inductionSystemList.forEach(val => {
          this.checkedSystemArray.push(val.uisId + '-' + val.systemName)
        })
      }
      this.configFormData.mainPeoples = row.maintenancePeople
      this.configFormData.upId = row.upId
      this.configVisible = true
    },
    // 单个状态按钮更新
    statusChange(row) {
      this.$confirm('此操作将更新状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            upId: row.upId,
            configSystemStatus: row.configSystemStatus
          }
          updateConfigureJobs(params).then(res => {
            if (res.code === '000000') {
              this.$message({
                showClose: true,
                message: '更新成功',
                type: 'success'
              })
            } else {
              this.$message.error('更新失败')
            }
          })
        })
        .catch(() => {
          // 更新不成功 状态不更新
          row.configSystemStatus = row.configSystemStatus === 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    handleSelectionChange(val) {
      this.upIdSeletionArray = val
    },
    // 批量更新状态
    batchValidation() {
      this.$confirm('此操作将批量更新状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const arr = []
          this.upIdSeletionArray.forEach(item => {
            arr.push(item.upId)
          })
          updateStatus(arr).then(res => {
            if (res.code === '000000') {
              this.$message({
                showClose: true,
                message: '更新成功',
                type: 'success'
              })
              this.getListConfigJob()
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消批量操作'
          })
        })
    },
    powerClick() {
      this.powerVisible = true
    },
    closeDialog(type) {
      if (type === 'config') {
        this.configVisible = false
      } else {
        this.powerVisible = false
      }
    },
    commitSuccess() {
      this.configVisible = false
      this.getListConfigJob()
    },
    onClear() {
      this.searchForm.upName = ''
      this.searchForm.upId = ''
    }
  }
}
</script>

<style>

</style>
