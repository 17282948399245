<template>
  <el-dialog
    title="入职岗位配置"
    :visible.sync="configVisible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closeDialog"
    v-ismove>
    <el-form :model="configFormData" :rules="formRule" ref="configFormData" :inline="true" size="small">
      <el-form-item label="IT负责人" prop="mainPeoples">
        <el-select v-model="configFormData.mainPeoples" size="small" clearable>
          <el-option v-for="mainPeople in mainPeoplesList" :label="mainPeople.name + '('+mainPeople.username+')'" :value="mainPeople.username" :key="mainPeople.username"/>
        </el-select>
      </el-form-item>
    </el-form>
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <el-button type="text" @click="innerVisible = true">编辑系统</el-button>
    <el-checkbox-group v-model="checkedArray" @change="handleCheckedChange">
      <el-checkbox v-for="system in systemList" :key="system.uisId" :label="system.uisId+'-'+system.systemName" style="width:200px">{{ system.systemName }}</el-checkbox>
    </el-checkbox-group>
    <!-- 系统管理弹窗 -->
    <el-dialog
      width="700px"
      title="管理系統"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body>
      <el-input v-model="systemName" size="small" style="width:300px"></el-input>
      <el-button type="primary" size="small" @click="addBtn" plain>新增该内容</el-button>
      <el-table
        ref="multipleTable"
        :data="systemList"
        :show-header="false"
        tooltip-effect="dark"
        style="width: 100%"
        height="500px"
        size="small">
        <el-table-column label="Title">
          <template slot-scope="scope">
            <template v-if="scope.row.edit">
              <el-input v-model="scope.row.systemName" class="edit-input" size="small" style="width:300px" />
              <el-button class="cancel-btn" size="small" type="warning" @click="cancelEdit(scope.row)" plain>取消</el-button>
            </template>
            <span v-else>{{ scope.row.originalTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.edit" type="primary" size="small" @click="confirmEdit(scope.row)" plain>保存</el-button>
            <el-button v-else type="success" size="small" @click="scope.row.edit=!scope.row.edit" plain>编辑</el-button>
            <el-button type="danger" size="small" @click="deleteBtn(scope.row)" plain>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" @click="commitCheck()">确 定</el-button>
    </div>
    </el-dialog>
</template>

<script>
import {
  inductionSystemList,
  maintenancePeoples,
  correlationSystem,
  updateSystem,
  addSystem,
  deleteSystem
} from '@/api/post'
import { requiredC } from '@/utils/validate'

export default {
  props: {
    configVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    checkedSystemArray: {
      type: Array,
      default() {
        return []
      }
    },
    configFormData: {
      type: Object,
      default() {
        return {
          upId: 0,
          mainPeoples: ''
        }
      }
    }
  },
  watch: {
    configVisible: function() {
      if (this.configVisible) {
        this.checkedArray = this.checkedSystemArray
      }
    }
  },
  data() {
    return {
      systemList: [],
      mainPeoplesList: [],
      checkAll: false,
      checkedAll: [],
      checkedArray: [],
      isIndeterminate: true,
      innerVisible: false,
      systemName: '',
      formRule: {
        mainPeoples: [requiredC()]
      }
    }
  },
  created() {
    this.getSystemList()
    // 获取负责人
    maintenancePeoples().then(res => {
      this.mainPeoplesList = res.data
    }).catch(() => {
      this.mainPeoplesList = []
    })
  },
  methods: {
    getSystemList() {
      // 系统列表
      inductionSystemList().then(res => {
        res.data.forEach(val => {
          this.checkedAll.push(val.uisId + '-' + val.systemName)
          // 是否在修改状态
          this.$set(val, 'edit', false)
          // 记录修改之前的系统名称
          val.originalTitle = val.systemName
        })
        this.systemList = res.data
        // this.$forceUpdate()
      }).catch(() => {
        this.systemList = []
      })
    },
    handleCheckAllChange(val) {
      this.checkedArray = val ? this.checkedAll : []
      this.isIndeterminate = false
    },
    handleCheckedChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.systemList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.systemList.length
    },
    // 编辑
    cancelEdit(row) {
      row.systemName = row.originalTitle
      row.edit = false
      this.$message({
        showClose: true,
        message: '已取消修改',
        type: 'warning'
      })
    },
    confirmEdit(row) {
      const params = {
        uisId: row.uisId,
        systemName: row.systemName
      }
      updateSystem(params).then(res => {
        if (res.code === '000000') {
          row.edit = false
          row.originalTitle = row.systemName
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success'
          })
        } else {
          row.edit = true
          row.name = row.originalTitle
        }
      }).catch(err => { console.log(err) })
    },
    deleteBtn(row) {
      this.$confirm('是否删除 ' + row.systemName, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSystem(row.uisId).then(res => {
          if (res.code === '000000') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
          }
          this.getSystemList()
        }).catch()
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    commitCheck() {
      this.$refs.configFormData.validate((valid) => {
        if (valid) {
          if (this.checkedArray.length > 0) {
            const arr = []
            this.checkedArray.forEach(element => {
              arr.push({
                uisId: element.split('-')[0],
                systemName: element.split('-')[1]
              })
            })
            const updateParams = {
              inductionSystemParams: arr,
              maintenancePeople: this.configFormData.mainPeoples,
              upId: this.configFormData.upId
            }
            correlationSystem(updateParams).then(res => {
              if (res.code === '000000') {
                this.$message({
                  showClose: true,
                  type: 'success',
                  message: '修改成功!'
                })
              }
              this.success()
            }).catch(err => {
              console.log(err)
            })
          } else {
            this.$message({
              showClose: true,
              type: 'warning',
              message: '请选择一个系统'
            })
          }
        }
      })
    },
    addBtn() {
      if (this.systemName) {
        addSystem({ systemName: this.systemName }).then(response2 => {
          if (response2.code === '000000') {
            this.systemName = ''
            this.$message({
              showClose: true,
              type: 'success',
              message: '成功!'
            })
            this.getSystemList()
          }
        }).catch()
      } else {
        this.$message({
          showClose: true,
          message: '请填写内容再新增操作',
          type: 'warning'
        })
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    success(val) {
      this.$emit('commitSuccess')
    }
  }
}
</script>

<style>

</style>
